import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthSkeleton from "Containers/Auth/AuthSkeleton";
import { NO_AUTH_REDIRECT_URL } from 'Configs/App';
import { useSelector } from 'react-redux';

interface IPrivateRouteProps {
    component: React.ComponentType<any>
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component: Component, ...rest }) => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLogged);
    if (!isLoggedIn) {
        return <Navigate to={NO_AUTH_REDIRECT_URL} />;
    }
    return (
        <AuthSkeleton>
            <Component {...rest} />
        </AuthSkeleton>
    )
}

export default PrivateRoute;