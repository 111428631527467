import { all } from 'redux-saga/effects';
import { watchAuth } from './authSaga';
import { watchInitApp } from './initAppSaga';
import { watchLogout } from './logoutSaga';

function* rootSaga() {
    yield all([
        watchAuth(),
        watchInitApp(),
        watchLogout(),
    ]);
}

export default rootSaga;