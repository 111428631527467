import { IPIVM_TOKEN } from 'Configs/App';
import { takeLatest, call, put } from 'redux-saga/effects';
import { AUTH_REQUEST, authFailure } from 'ReduxSaga/Actions/AuthActions';
import { startInitApp } from 'ReduxSaga/Actions/InitAppActions';
import { finishLoading, startLoading } from 'ReduxSaga/Actions/LoadingActions';
import { IAction } from 'ReduxSaga/types';
import UserRepo from 'Repositories/UserRepo';

function* authSaga(action: IAction) : Generator<any, void, any> {
    yield put(startLoading());
    try {
        const { username, password } = action.payload;
        const res = yield call(UserRepo.auth, { username, password });
        if (res.data) {
            localStorage.setItem(IPIVM_TOKEN, JSON.stringify(res.data));
            yield put(startInitApp());
        } else {
            yield put(authFailure(res.error.message));
        }
    } catch (error:any) {
        throw error;
    }
    yield put(finishLoading());
}

export function* watchAuth() {
    yield takeLatest(AUTH_REQUEST, authSaga);
}