import React, { useEffect, useState } from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import { useParams } from 'react-router';
import UserRepo from 'Repositories/UserRepo';
import Loading from 'Components/Loading';
import ResetPasswordInValid from './ResetPasswordInValid';

const ResetPasswordScreen = () => {
    const { email = "", token = "" } = useParams() || "";
    const [validToken, setValidToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkTokenValidation = async () => {
            try {
                const response = await UserRepo.validTokenResetPassword({ email: email, token: token });
                setValidToken(response.success);
            } catch (error) {
                console.error('Error checking token validity:', error);
            } finally {
                setIsLoading(false);
            }
        };
        checkTokenValidation();
    }, [email, token]);
    
    if (isLoading) {
        return <Loading />
    }
    return validToken ? <ResetPasswordForm /> : <ResetPasswordInValid />;
}
export default ResetPasswordScreen;