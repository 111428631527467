import { startLoading, finishLoading } from 'ReduxSaga/Actions/LoadingActions';
import { useDispatch } from "react-redux";
import { useCallback } from 'react';

const useLoading = () => {
    const dispatch = useDispatch();

    const handleLoading = useCallback(async (callback: () => Promise<void>) => {
        dispatch(startLoading());
        try {
            await callback();
        } catch (error) {
            console.error('Error occurred:', error);
            dispatch(finishLoading());
        } finally {
            dispatch(finishLoading());
        }
    }, [dispatch]);

    return handleLoading;
}
export default useLoading;