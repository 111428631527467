import { combineReducers } from 'redux';
import loadingReducer from 'ReduxSaga/Reducers/Loading/LoadingReducer';
import authReducer from 'ReduxSaga/Reducers/Auth/authReducer';
import initAppReducer from './App/initAppReducer';

const rootReducer = combineReducers({
  loading: loadingReducer,
  auth: authReducer,
  init: initAppReducer,

});

export default rootReducer;
