import React from "react";
import './Button.scss';
import useLoading from 'Services/useLoading';
interface IButtonProps {
    onClick?: () => Promise<void>,
    text: string
    isLoading?: boolean
}

const Button: React.FC<IButtonProps> = ({
    onClick,
    text,
    isLoading
}) => {
    const handleLoading = useLoading();
    const onClickHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (onClick != null) {
            if (isLoading) {
                await handleLoading(onClick);
            } else {
                onClick();
            }
        }
    }
    return (
        <button onClick={onClickHandle}>{text}</button>
    );

}

export default Button;