import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";

import Dashboard from "./Dashboard";

export default {
    Login,
    ForgotPassword,
    ResetPassword,

    Dashboard
}