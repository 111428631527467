import { START_LOADING, FINISH_LOADING } from 'ReduxSaga/Actions/LoadingActions';
import { IAction, ILoadingState } from 'ReduxSaga/types';

const initialState: ILoadingState = {
    isLoading: false,
}

const loadingReducer = (state: ILoadingState = initialState, action: IAction): ILoadingState => {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case FINISH_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default loadingReducer;