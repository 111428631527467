import React from 'react';
import Button from 'Components/Button';
import { useDispatch } from 'react-redux';
import { authReset } from 'ReduxSaga/Actions/AuthActions';

const DashboardScreen = () => {
    const dispatch = useDispatch();
    const handleButton = async () => {
        dispatch(authReset());

        console.log("hihi");
    }
    return (
        <>
            <h1>This is deskboard Page</h1>
            <p>sada</p>
            <Button onClick={handleButton} isLoading={true} text="logout"/>
        </>
    );
}

export default DashboardScreen;