import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink } from 'react-router-dom'
import Button from 'Components/Button';
import { useTranslation } from "react-i18next";
import useSetLocalYupService from 'Services/useSetLocalYupService';
import { authRequest } from "ReduxSaga/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { ILoginRequest } from "DTOs/User";


const LoginForm = () => {

    const loginError = useSelector((state: any) => state.auth.error);
    const [error, setError] = useState<string>("");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setError(loginError);
    }, [loginError]);

    useSetLocalYupService();

    const schema = yup.object().shape({
        username: yup.string().email().required().label(t('auth.username')),
        password: yup.string().required().min(8).label(t('auth.password'))
    });

    const { register: formValue, handleSubmit, formState: { errors } } = useForm<ILoginRequest>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (params: ILoginRequest) => {
        dispatch(authRequest(params));
    }
    const handleButtonClick = async () => {
        await handleSubmit(onSubmit)();
    };
    return (
        <form>
            <h4 className="auth_title">{t("auth.title")}</h4>
            <div className='form_group'>
                <input placeholder="name@example.com" {...formValue("username")} />
            </div>
            <div className='form_group'>
                <input type="password" placeholder="Enter password" {...formValue("password")} />
            </div>
            <div className='form_group'>
                <span className='error_message_form'>{errors.username?.message}</span>
                <span className='error_message_form'>{errors.password?.message}</span>
                <span className='error_message_form'>{error}</span>
            </div>
            <div className='form_group txt_right'>
                <NavLink to="/auth/forgot-password">{t('auth.forgotPassword')}</NavLink>
            </div>
            <Button onClick={handleButtonClick} text={t('auth.login')} />
        </form>
    )
}

export default LoginForm;