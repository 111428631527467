import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink } from 'react-router-dom'
import UserRepo from "Repositories/UserRepo";
import Button from 'Components/Button';
import { useTranslation } from "react-i18next";
import { APP_URL } from 'Configs/App';
import useSetLocalYupService from 'Services/useSetLocalYupService';

interface IForgotPasswordObject {
    email: string,
    callbackDomain: string
}

const ForgotPasswordForm = () => {

    const { t } = useTranslation();
    const [error, setError] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    useSetLocalYupService();

    const schema = yup.object().shape({
        email: yup.string().email().required().label(t('auth.email')),
        callbackDomain: yup.string().required().label(t('auth.callbackDomain')),
    })

    const { register: formValue, handleSubmit, formState: { errors } } = useForm<IForgotPasswordObject>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (params: IForgotPasswordObject) => {
        const res = await UserRepo.forgotPassword(params);
        if (res.data) {
            setMessage(res.data.message);
        }
        else {
            setError(res.error.message);
        }
    }

    const handleButtonClick = async () => {
        setError("");
        setMessage("");
        await handleSubmit(onSubmit)();
    }

    return (
        <form>
            <h4 className="auth_title" >{t('auth.forgotPassword')}</h4>
            <div className='form_group'>
                <input type="text" placeholder="name@example.com" {...formValue("email")} />
            </div>
            <input type="hidden" {...formValue("callbackDomain")} value={APP_URL} />
            <div className='form_group'>
                <span className='error_message_form'>{errors.email?.message}</span>
                <span className='error_message_form'>{error}</span>
                <span className='info_message_form'>{message}</span>
            </div>
            <div className='form_group txt_right'>
                <NavLink to="/auth/login">{t('auth.backToLogin')} </NavLink>
            </div>
            <Button onClick={handleButtonClick} isLoading={true} text={t('auth.resetPasswordRequest')} />
        </form>
    )
}
export default ForgotPasswordForm;