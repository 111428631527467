import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { NavLink, useParams } from 'react-router-dom'
import UserRepo from "Repositories/UserRepo";
import Button from 'Components/Button';
import { useTranslation } from "react-i18next";
import useSetLocalYupService from 'Services/useSetLocalYupService';
import { IResetPasswordRequest } from 'DTOs/User';
const ResetPasswordForm = () => {

    const { t } = useTranslation();
    const [error, setError] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const { email = "", token = "" } = useParams() || "";

    useSetLocalYupService();

    const schema = yup.object().shape({
        password: yup.string()
            .required()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                t('auth.passwordNotMatchRegex')
            )
            .label(t('auth.password')),
        confirm_password: yup.string()
            .oneOf([yup.ref('password')])
            .required()
            .label(t('auth.confirmPassword')),
    });

    const { register: formValue, handleSubmit, formState: { errors } } = useForm<IResetPasswordRequest>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (params: IResetPasswordRequest) => {
        const res = await UserRepo.changePassword({ email: email, token: token }, params);
        if (res.data) {
            setMessage(res.data.message);
        }
        else {
            setError(res.error.message);
        }
    }

    const handleButtonClick = async () => {
        setError("");
        setMessage("");
        await handleSubmit(onSubmit)();
    }

    if (error || message) {
        return (
            <form>
                <h4 className="auth_title" >{t('auth.changePassword')}</h4>
                <div className='form_group'>
                    <span className='error_message_form'>{error}</span>
                    <span className='info_message_form'>{message}</span>
                </div>
                <div className='form_group txt_right'>
                    <NavLink to="/auth/login">{t('auth.backToLogin')} </NavLink>
                </div>
            </form>
        )
    }

    return (
        <form>
            <h4 className="auth_title" >{t('auth.changePassword')}</h4>
            <div className='form_group'>
                <input type="password" placeholder="password" {...formValue("password")} />
            </div>
            <div className='form_group'>
                <input type="password" placeholder="comfirm password" {...formValue("confirm_password")} />
            </div>
            <div className='form_group'>
                <span className='error_message_form'>{errors.password?.message}</span>
                <span className='error_message_form'>{errors.confirm_password?.message}</span>
            </div>
            <div className='form_group txt_right'>
                <NavLink to="/auth/login">{t('auth.backToLogin')} </NavLink>
            </div>
            <Button onClick={handleButtonClick} isLoading={true} text={t('auth.changePasswordConfirm')} />
        </form>
    )
}
export default ResetPasswordForm;