import React from 'react';
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const ResetPasswordInValid = () => {

    const { t } = useTranslation();

    return (
        <form>
            <h4 className="auth_title" >{t('auth.changePassword')}</h4>
            <div className='form_group'>
                <span className='error_message_form'>{t('auth.invalidToken')}</span>
            </div>
            <div className='form_group txt_right'>
                <NavLink to="/auth/login">{t('auth.backToLogin')} </NavLink>
            </div>
        </form>
    )
}
export default ResetPasswordInValid;