import Components from "Containers";
export const authRoutes = [
    {
        key: "SignIn Page",
        component: Components.Login,
        exact: true,
        path: "/auth/login",
        title: "sign_in",
    },
    {
        key: "Forgot Password Page",
        component: Components.ForgotPassword,
        exact: true,
        path: "/auth/forgot-password",
        title: "forgot_password",
    },
    {
        key: "Reset Password Page",
        component: Components.ResetPassword,
        exact: true,
        path: "/reset-password/:email/:token",
        title: "reset_password",
    },
];
export const privateRoutes = [
    {
        key: "Dashboard Page",
        component: Components.Dashboard,
        exact: true,
        path: "/",
        title: "dashboard",
    },
];
