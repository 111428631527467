import { API_ENDPOINT, LOGIN_PATH, FORGOT_PASSWORD_PATH, USER_PROFILE_PATH, VALID_TOKEN_RESET_PASSWORD_PATH, CHANGE_PASSWORD_PATH } from "Configs/PIVMApi";
import { IForgotPasswordRequest, ILoginRequest, IResetPasswordRequest } from "DTOs/User";
import { getAPIWithToken, postAPI, postAPIWithToken } from 'Services/useAPI';

const auth = async (params: ILoginRequest) => {
    try {
        const res = await postAPI(API_ENDPOINT + LOGIN_PATH, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const forgotPassword = async (params: IForgotPasswordRequest) => {
    try {
        const res = await postAPI(API_ENDPOINT + FORGOT_PASSWORD_PATH, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const changePassword = async (pathParams: { email: string, token: string }, params: IResetPasswordRequest) => {
    try {
        const fullPath = CHANGE_PASSWORD_PATH.replace(/:email|:token/g, match => {
            switch (match) {
                case ':email':
                    return pathParams.email;
                case ':token':
                    return pathParams.token;
                default:
                    return "";
            }
        });
        const res = await postAPI(API_ENDPOINT + fullPath, params);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const validTokenResetPassword = async (pathParams: { email: string, token: string }) => {
    try {
        const fullPath = VALID_TOKEN_RESET_PASSWORD_PATH.replace(/:email|:token/g, match => {
            switch (match) {
                case ':email':
                    return pathParams.email;
                case ':token':
                    return pathParams.token;
                default:
                    return "";
            }
        });
        const res = await postAPI(API_ENDPOINT + fullPath);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const getProfile = async () => {
    try {
        const res = await getAPIWithToken(API_ENDPOINT + USER_PROFILE_PATH);
        return res.data;
    } catch (error: any) {
        throw error;
    }
}

const UserRepo = {
    auth,
    forgotPassword,
    changePassword,
    validTokenResetPassword,
    getProfile
};

export default UserRepo;