import React, {ReactNode} from 'react';
import './AuthSkeleton.scss'
import logo from "Assets/Images/logo.png";

interface IAuthSkeletonProps {
    children: ReactNode;
}

const AuthSkeleton: React.FC<IAuthSkeletonProps> = ({children}) => {
    return (
        <div className='auth_board'>
            <div className="auth_box">
                <div className='auth_logo'>
                    <img src={logo} alt='pivm logo' />
                </div>
                <div className='auth_form'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AuthSkeleton;