import { IPIVM_TOKEN } from 'Configs/App';
import { takeLatest, put } from 'redux-saga/effects';
import { AUTH_RESET } from 'ReduxSaga/Actions/AuthActions';
import { finishLoading, startLoading } from 'ReduxSaga/Actions/LoadingActions';


function* logoutSaga(): Generator<any, void, any> {
    yield put(startLoading());
    try {
        yield localStorage.removeItem(IPIVM_TOKEN);
    } catch (error: any) {
        throw error;
    }
    yield put(finishLoading());
}

export function* watchLogout() {
    yield takeLatest(AUTH_RESET, logoutSaga);
}