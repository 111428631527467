import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import commonUS from "Assets/i18n/us/common.json";
import commonVN from "Assets/i18n/vn/common.json";
import authUS from "Assets/i18n/us/auth.json";
import authVN from "Assets/i18n/vn/auth.json";

import validationUS from "Assets/i18n/us/validation.json";
import validationVN from "Assets/i18n/vn/validation.json";


const resources = {
    "en-US": {
        "translation": {
            "common": commonUS,
            "auth": authUS,
            "validation": validationUS
        }

    },
    "vi-VN": {
        "translation": {
            "common": commonVN,
            "auth": authVN,
            "validation": validationVN
        }
    },
};

i18n.use(LanguageDetector)
    .init({
        resources: resources,
        fallbackLng: "vi-VN",
        debug: true,
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },

        react: {
            useSuspense: false,
        },
    });

export default i18n;