import { IPIVM_TOKEN } from 'Configs/App';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { authSuccess, loginFailure, loginSuccess } from 'ReduxSaga/Actions/AuthActions';
import { finishInitApp, START_INIT_APP } from "ReduxSaga/Actions/InitAppActions";
import UserRepo from 'Repositories/UserRepo';

export function* initAppSaga(): Generator<any, void, any> {
    try {
        const tokenString = localStorage.getItem(IPIVM_TOKEN);

        if (!tokenString) {
            yield put(loginFailure());
        } else {
            const tokenInfo = JSON.parse(tokenString);
            
            const expireTime = new Date(tokenInfo.expires_at).getTime();
            const currentTime = new Date().getTime();
            if(expireTime < currentTime){
                localStorage.removeItem(IPIVM_TOKEN);
                yield put(loginFailure());
            }else{
                yield put(authSuccess(tokenInfo));
                const { userInfo } = yield all([
                    call(UserRepo.getProfile),
                ]);
                yield all([
                    put(loginSuccess(userInfo)),
                ]);
            }
        }
        yield put(finishInitApp());

    } catch (error: any) {
        console.error("appSaga :" + error);
    }
}

export function* watchInitApp() {
    yield takeLatest(START_INIT_APP, initAppSaga);
}