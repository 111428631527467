import Axios from 'axios';
import { IPIVM_TOKEN } from 'Configs/App';

export const getAPIWithToken = async (urlAPI: string) => {
    try {
        const accessInfo = localStorage.getItem(IPIVM_TOKEN);
        if (!accessInfo) {
            throw new Error('Token not found in localStorage');
        }
        const token = JSON.parse(accessInfo).access_token;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.get(urlAPI, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const postAPIWithToken = async (urlAPI: string, params: object = {}) => {
    try {
        const accessInfo = localStorage.getItem(IPIVM_TOKEN);
        if (!accessInfo) {
            throw new Error('Token not found in localStorage');
        }
        const token = JSON.parse(accessInfo).access_token;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.post(urlAPI, params, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const putAPIWithToken = async (urlAPI: string, params: object = {}) => {
    try {
        const accessInfo = localStorage.getItem(IPIVM_TOKEN);
        if (!accessInfo) {
            throw new Error('Token not found in localStorage');
        }
        const token = JSON.parse(accessInfo).access_token;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.put(urlAPI, params, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const deleteAPIWithToken = async (urlAPI: string, params: object = {}) => {
    try {
        const accessInfo = localStorage.getItem(IPIVM_TOKEN);
        if (!accessInfo) {
            throw new Error('Token not found in localStorage');
        }
        const token = JSON.parse(accessInfo).access_token;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.delete(urlAPI, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const getAPI = async (urlAPI: string) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.get(urlAPI, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const postAPI = async (urlAPI: string, params: object = {}) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.post(urlAPI, params, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const putAPI = async (urlAPI: string, params: object = {}) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.put(urlAPI, params, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

export const deleteAPI = async (urlAPI: string) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const config = {
            headers: headers
        };

        const res = await Axios.delete(urlAPI, config);
        return res;
    } catch (error: any) {
        throw error;
    }
}

