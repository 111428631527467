import { ILoginRequest } from "DTOs/User";

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTH_RESET = 'AUTH_RESET';


export const authRequest = (credentials: ILoginRequest) => ({
    type: AUTH_REQUEST,
    payload: credentials,
});
export const authSuccess = (tokenInfo: object) => ({
    type: AUTH_SUCCESS,
    payload: tokenInfo,

});
export const authFailure = (error: any) => ({
    type: AUTH_FAILURE,
    payload: error
});
export const loginSuccess = (userData: object) => ({
    type: LOGIN_SUCCESS,
    payload: userData
});
export const loginFailure = () => ({
    type: LOGIN_FAILURE
});
export const authReset = () => ({
    type: AUTH_RESET
});